<template>
	<div>
		<!-- 头部logo -->
		<headLogo @navigation="navigation"></headLogo>
		
		<!-- 头部导航 -->
		<navigation :headTitle="headTitle" :headImg="headImg" style="margin-top:96px;"></navigation>
		
		<div class="productions">
			<!-- 数据 -->
			<el-row class="productions-data">
				<el-row v-if="notData">
					<div class="productions-bigbox" v-for="(item,id) in ListData" @click="learn(item)" :key="id"> 
						<div class="productions-imgbox">
							<img :src="proNotLoadImage" :onload="onLoadImg(item.pictureUrl)" class="productions-img"></img>
						</div> 
						<div class="productions-databox">
							<el-row>
								<el-col :span="1">
									<el-image src="/index/vertical.png" class="Pro-VerticalImg"></el-image>
								</el-col>
								<el-col :span="19" :offset="2">
									<el-row class="productions-title">
										{{item.title}}
									</el-row>
									<el-row v-html="item.bodyContent" class="productions-message">
										{{item.bodyContent}}
									</el-row>
								</el-col>
								<el-col :span="3" style="text-align: right;">
									<el-row v-if="id+1<10" class="productions-number">
										0{{id+1}}
									</el-row>
									<el-row v-else class="productions-number">
										{{id+1}}
									</el-row>
									<el-row class="productions-buttonbox">
										<button class="productions-button">Learn More</button>
									</el-row>
								</el-col>
							</el-row>
						</div> 
					</div> 
					<el-row>
						<button v-if="loading" class="productions-btn" @click="load">Load More</button>
					</el-row>
				</el-row>
				<el-row v-else>
					<el-row>
						<el-image src="/index/nodata.png" class="error-img"></el-image>
					</el-row>
					<el-row class="error-message">
						Empty data
					</el-row>
				</el-row>
			</el-row>
		</div>
		
		<!-- 菜单导航 -->
		<meauDialog :meauValue="navigationvalue" @meauFn="menu"></meauDialog>
		
		<!-- 底部导航 -->
		<bottomNavigation></bottomNavigation>
	</div>
</template>

<script>
	// 图片查询接口
	import { selectPictureListById , selectContentListById } from '@/api/index.js'

	// 头部组件
	import headLogo from '@/components/headLogo.vue'
	// 头部导航
	import navigation from '@/components/navigation.vue'
	// 底部导航
	import bottomNavigation from '@/components/bottomNavigation.vue'
	// 菜单导航
	import meauDialog from '@/components/meauDialog.vue'
	export default
	{
		data()
		{
			return{
				// 头部导航标题
				headTitle:'PRODUCTIONS',
				// 头部导航图片
				headImg:'/index/production.png',
				// 导航值
				navigationvalue:false,
				
				// 默认显示条数
				count:5,
				// 加载按钮
				loading:true,
				
				list:[],
				notData:true,
				proNotLoadImage: "/PCimage/1100_480.jpg", //产品图片未加载出来
			}
		},
		components:
		{
			headLogo,
			navigation,
			bottomNavigation,
			meauDialog
		},				
		computed:
		{
			// 计算属性
			ListData()
			{
				let data=[];
				if(this.list.length>5)
				{
					data=this.list.slice(0,this.count)
					return data;
				}
				else
				{
					data=this.list;
					this.loading=false;
					return data;
				}
			},
		},
		created()
		{
			this.getList();
		},
		methods:
		{
			// 头部传来的数据
			navigation(v)
			{
				this.navigationvalue=v;
				console.log(this.navigationvalue,'头部传递菜单导航')
			},
			// 遮罩层传回来的数据
			menu(v)
			{
				this.navigationvalue=v;
			},
			getList()
			{
				selectContentListById(
				{
				  chnanelType: 1,
				  isIndex: 0,
				  moduleType: 'MODULE_PRODUCTIONS'
				})
				.then((response)=>
				{
					if(response.data.data=='')
					{
						this.notData=false;
					}
					else
					{
						this.notData=true;
						this.loading=true;
						this.list=response.data.data;
					}
				})
			},
			// 加载更多
			load()
			{
				this.count+=5;
				if(this.count>=this.list.length)
				{
					this.loading=false
				}
			},
			learn(item)
			{
				localStorage.setItem('Details',JSON.stringify(item));
				this.$router.push(
				{
					path:'/noticesDetails',
					query:
					{
						item:item,
						headTitle:'PRODUCTIONS',
						headImg:'/index/music.png',
					}
				})
			},
			
			// 图片加载完成时触发
			onLoadImg(imgSrc) 
			{
				return 'this.src='+'"'+imgSrc+'";this.οnlοad=null';
			},
		}
	}
</script>

<style scoped>
	@import '../assets/production.css';
	.productions{
		width: 1050px;
		/* width: 1170px; */
		margin:0 auto;
	}
	.productions-number{
		font-size: 72px;
		color: #CCCCCC;
		font-weight: Arial-Black;
	}
	.productions-button{
		width: 100px;
		height: 32px;
		line-height: 20px;
		border: none;
		border-radius: 50px;
		margin-top: 10px;
		margin-bottom: 10px;
		background-color: #e2dddd;
		color: rgb(80,80,80,0.5)!important; 
		 /* #f6f5f8; */
		/* background-color: #F6F5F8; */
		/* color: #F6F5F8 !important; */
	}
	
	.productions-imgbox:hover ~ .productions-databox .productions-button,.productions-databox:hover .productions-button{
		width: 100px;
		height: 32px;
		line-height: 20px;
		border: none;
		border-radius: 50px;
		background-color: #FCD002;
		color: #505050 !important;
		font-size: 16px;
		color: #505050;
	}
	.productions-imgbox:hover ~ .productions-databox .productions-title,.productions-databox:hover .productions-title{
		color: #505050;
	} 
	.productions-imgbox:hover ~ .productions-databox .productions-message,.productions-databox:hover .productions-message{
		color: #505050;
	} 
	.productions-btn{
		width: 400px;
		height: 50px;
		line-height: 25px;
		border: none;
		border-radius: 50px;
		background-color: #FCD002;
		color: #505050;
		font-size: 24px;
		color: #505050;
	}
	.productions-nobtn{
		width: 30%;
		height: 40px;
		border: none;
		border-radius: 50px;
		font-size: 15px;
		margin-top: 50px;
		background-color: #ccc;
		color: #505050;
	}
	.error-img{
		width: 300px;
		height: 200px;
		margin-top: 100px;
	}
	.error-message{
		margin-top: 30px;
		font-size: 20px;
		color: #3B519D;
		margin-bottom: 50px;
	}
</style>